/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  assignPlanModuleList: [],
  planData: [],
};

const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    setPlanModuleList: (state, action) => {
      state.assignPlanModuleList = action?.payload;
    },
    removeModuleFromList: (state, action) => {
      state.assignPlanModuleList = state?.assignPlanModuleList?.filter(
        (id) => id !== action?.payload
      );
    },
    addModuleFromList: (state, action) => {
      state.assignPlanModuleList = [
        ...state.assignPlanModuleList,
        action?.payload,
      ];
    },
    setPlanListList: (state, action) => {
      state.planData = action?.payload;
    },
    clearSetStates: (state) => {
      state.assignPlanModuleList = [];
    },
  },
});

export const {
  setPlanModuleList,
  addModuleFromList,
  removeModuleFromList,
  clearSetStates,
  setPlanListList,
} = planSlice.actions;

export default planSlice.reducer;
