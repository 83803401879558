import onLoadLogo from "../../assets/image/onLoadLogo.png";

export const InitialLoadUi = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: `translate(${-50}%, ${-50}%)`,
        width: "60px",
      }}
    >
      <img src={onLoadLogo} alt="logo" />
    </div>
  );
};
