/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  breakList: {},
  getBreakStatusList: [],
  editBreakData: {},
  addBreakData: {},
  breakListData: [],
};

const breakSlice = createSlice({
  name: "break",
  initialState,
  reducers: {
    getBreakList: (state, action) => {
      state.breakList = action.payload;
    },
    getAllStatusPayloadData: (state, action) => {
      state.getBreakStatusList = action.payload;
    },

    setAddBreakData: (state, action) => {
      state.addBreakData = action.payload;
    },
    setEditBreakData: (state, action) => {
      state.editBreakData = action.payload;
    },
    clearBreakData: (state) => {
      state.editBreakData = {};
      state.breakList = {};
    },
    setBreakList: (state, action) => {
      state.breakListData = action.payload;
    },
  },
});
export const {
  getBreakList,
  getAllStatusPayloadData,
  setEditBreakData,
  clearBreakData,
  setAddBreakData,
  setBreakList,
} = breakSlice.actions;
export default breakSlice.reducer;
