/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  attendenceOption: {},
};

const attendenceSlice = createSlice({
  name: "attendence",
  initialState,
  reducers: {
    setAttendenceOption: (state, action) => {
      state.attendenceOption = action.payload;
    },
  },
});
export const { setAttendenceOption } = attendenceSlice.actions;
export default attendenceSlice.reducer;
