// eslint-disable-next-line import/namespace
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  empListForPaySlip: [],
  isSelectAll: false,
};

const payrollSlice = createSlice({
  name: "payroll",
  initialState,
  reducers: {
    getPayrollDetails: (state, action) => {
      state.getPayrollDetailsList = action.payload;
    },

    setSelectedEmpForPayslip: (state, action) => {
      state.empListForPaySlip = [...state.empListForPaySlip, action.payload];
    },

    removeSelectedEmpForPayslip: (state, action) => {
      state.empListForPaySlip = state.empListForPaySlip?.filter((item) => {
        return item !== action.payload;
      });
    },

    setSelectAll: (state, action) => {
      state.isSelectAll = action.payload;
    },

    setAllSelectedEmployeeList: (state, action) => {
      state.empListForPaySlip = action.payload
        ?.filter((item) => {
          return item?.isConfirmByMail !== true;
        })
        .map((_empId) => {
          return _empId?.employeeId;
        });
    },

    clearSelectedEmpForPayslip: (state) => {
      state.empListForPaySlip = [];
      state.isSelectAll = false;
    },
  },
});
export const {
  setSelectedEmpForPayslip,
  removeSelectedEmpForPayslip,
  getPayrollDetails,
  setSelectAll,
  clearSelectedEmpForPayslip,
  setAllSelectedEmployeeList,
} = payrollSlice.actions;
export default payrollSlice.reducer;
