/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  message: "",
  type: {},
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
    },

    clearMessage: (state) => {
      state.message = "";
      state.type = {};
    },
  },
});
export const { setMessage, clearMessage } = toastSlice.actions;
export default toastSlice.reducer;
