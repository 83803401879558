/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  departmentList: [],
  designationList: [],
};

const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    setDepartmentList: (state, action) => {
      state.departmentList = action.payload;
    },
    setDesignationList: (state, action) => {
      state.designationList = action.payload;
    },
  },
});
export const { setDepartmentList, setDesignationList } = masterSlice.actions;
export default masterSlice.reducer;
