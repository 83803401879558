import { lazy } from "react";

// success layout
export const LazySuccessLayout = lazy(() =>
  import("../components/success-layout")
);

export const LazyAdd = lazy(() =>
  import("../pages/attendance-management/employee/addemployee")
);

//Loan Module

export const LazyLoanMasterType = lazy(() =>
  import("../pages/loan-management/loan-master/loan-type")
);
export const LazyLoanMasterRepayment = lazy(() =>
  import("../pages/loan-management/loan-master/repayment-mode")
);

export const LazyApplication = lazy(() =>
  import("../pages/loan-management/loan-application/application")
);

export const LazyMyLoan = lazy(() =>
  import("../pages/loan-management/loan-application/my-loan")
);

export const LazyRecordList = lazy(() =>
  import("../pages/loan-management/record-loan/record-list")
);

export const LazyAddRecord = lazy(() =>
  import("../pages/loan-management/record-loan/add-record")
);

// export const LazyMasterRecordTab = lazy(() =>
// import ("../pages/loan-management/LoanMasterTabData")
// );

// export const LazyRecordLoanAddRecord = lazy(()=>
// import("../pages/loan-management/record-loan/add-record"))

// Leave Module
export const LazyLeaveBreakTabs = lazy(() =>
  import("../pages/attendance-management/leave-break/LeaveBreakTabs")
);
export const LazyLeave = lazy(() =>
  import("../pages/attendance-management/leave-break/leave/leave-list")
);
export const LazyBreak = lazy(() =>
  import("../pages/attendance-management/leave-break/break/break-list")
);

export const LazySalarySlip = lazy(() =>
  import("../pages/payroll/salary-slip")
);
export const LazySalaryComponent = lazy(() =>
  import("../pages/payroll/salary-component")
);
export const LazyStatutoryComponent = lazy(() =>
  import("../pages/payroll/statutory-component")
);
// Appraisal Module
export const LazyAppraisal = lazy(() => import("../pages/appraisal"));
export const LazyAppraisalList = lazy(() =>
  import("../pages/appraisal/appraisal-list")
);
export const LazyGetAppraisalList = lazy(() =>
  import("../pages/appraisal/appraisal-list/get-appraisal")
);
export const LazyViewAppraisal = lazy(() =>
  import("../pages/appraisal/appraisal-list/view-appraisal")
);
export const LazyViewRatingAppraisal = lazy(() =>
  import("../pages/appraisal/appraisal-list/view-rating-appraisal")
);

export const LazyFormAppraisal = lazy(() =>
  import("../pages/appraisal/appraisal-list/rating-form-appraisal")
);
export const LazykeyPerfomingIndicators = lazy(() =>
  import(
    "../pages/appraisal/appraisal-list/rating-form-appraisal/key-perfom-indicators"
  )
);
export const LazyFeedBackComments = lazy(() =>
  import(
    "../pages/appraisal/appraisal-list/rating-form-appraisal/feedback-comments"
  )
);

// Payroll Module...

export const LazyPayRoll = lazy(() => import("../pages/payroll"));
export const LazyGetPayRoll = lazy(() =>
  import("../pages/payroll/payroll-organisation")
);
export const LazyAddPayRoll = lazy(() =>
  import("../pages/payroll/add-payroll")
);
// HR Policy Handbook
export const LazyHRHandbookTabs = lazy(() =>
  import("../pages/attendance-management/hr-handBook/handBookTabs")
);
export const LazyHandbook = lazy(() =>
  import("../pages/attendance-management/hr-handBook/hr-handBook")
);
export const LazyPolicy = lazy(() =>
  import("../pages/attendance-management/hr-handBook/hr-policy")
);

// Documents
export const LazyDocuments = lazy(() => import("../pages/documents/documents"));
export const LazyConfirmationLetter = lazy(() =>
  import("../pages/documents/confirmation-letter")
);
export const LazyIncrementLetter = lazy(() =>
  import("../pages/documents/increment-letter")
);
export const LazyRelievingExperienceLetter = lazy(() =>
  import("../pages/documents/relieving-experience-letter")
);
// Ticket management
export const LazyTicketManagement = lazy(() =>
  import("../pages/ticket-management")
);
//Holiday
export const LazyHolidayRuleTabs = lazy(() =>
  import("../pages/attendance-management/holiday-rule/holidayRuleTabData")
);
export const LazyHoliday = lazy(() =>
  import("../pages/attendance-management/holiday-rule/holiday")
);
export const LazyHolidayGroup = lazy(() =>
  import("../pages/attendance-management/holiday-rule/holiday-group")
);

// Master
export const LazyMasterTabs = lazy(() =>
  import("../pages/attendance-management/masters/masterTabs")
);
export const LazyDepartment = lazy(() =>
  import("../pages/attendance-management/masters/department")
);
export const LazyDesignation = lazy(() =>
  import("../pages/attendance-management/masters/designation")
);
export const LazyUnit = lazy(() =>
  import("../pages/attendance-management/masters/unit")
);
export const LazyArea = lazy(() =>
  import("../pages/attendance-management/masters/area")
);
// Leave Rule
export const LazyLeaveRuleTabsTabs = lazy(() =>
  import("../pages/attendance-management/leave-rule/leaveRuleTabs")
);
export const LazyGroup = lazy(() =>
  import("../pages/attendance-management/leave-rule/group")
);

export const LazyType = lazy(() =>
  import("../pages/attendance-management/leave-rule/type")
);
export const LazyPlan = lazy(() =>
  import("../pages/attendance-management/leave-rule/plan")
);
export const LazyTeam = lazy(() => import("../pages/staffing/team/team-list"));

// Staffing Job-Opening Process
export const LazyJobOpeningProcess = lazy(() =>
  import("../pages/staffing/job-openings/job-opening-process")
);
export const LazyUploadResume = lazy(() =>
  import("../pages/staffing/job-openings/job-opening-process/upload-resume")
);
export const LazyViewUploadedResume = lazy(() =>
  import(
    "../pages/staffing/job-openings/job-opening-process/upload-resume/view-uploaded-resume"
  )
);
export const LazySetInterView = lazy(() =>
  import("../pages/staffing/job-openings/job-opening-process/set-interview")
);
export const LazyJobOffer = lazy(() =>
  import("../pages/staffing/job-openings/job-opening-process/job-offer")
);

export const LazyJobOfferList = lazy(() =>
  import(
    "../pages/staffing/job-openings/job-opening-process/job-offer/view-offer"
  )
);

export const LazyAddClosingBlock = lazy(() =>
  import("../pages/staffing/job-openings/job-opening-process/add-resume")
);

// Staffing activities
export const LazyActivityTabs = lazy(() =>
  import("../pages/staffing/activity/activityTabs")
);
export const LazyTargetSubmission = lazy(() =>
  import("../pages/staffing/activity/target-submission")
);
export const LazyOfferHire = lazy(() =>
  import("../pages/staffing/activity/offer-hire")
);
export const LazySearchResume = lazy(() =>
  import("../pages/staffing/activity/search-resume")
);

//  Job-Opening-Offer
export const LazyAddOffer = lazy(() =>
  import(
    "../pages/staffing/job-openings/job-opening-process/job-offer/add-offer"
  )
);

export const LazyOfferexpiredLink = lazy(() =>
  import(
    "../pages/staffing/job-openings/job-opening-process/job-offer/offer-expired-link"
  )
);

// Organization Multi-Tenant

export const LazyOrganization = lazy(() =>
  import("../pages/multi-tenant/organization")
);
export const LazyCrateOrganization = lazy(() =>
  import("../pages/multi-tenant/organization/sign-up")
);
export const LazyEmailVerification = lazy(() =>
  import("../pages/multi-tenant/organization/email-verification")
);

// Attedance -Rule
export const LazyAttedanceRule = lazy(() =>
  import("../pages/attendance-management/attedance-rule")
);
export const LazyAttedanceIPBiding = lazy(() =>
  import("../pages/attendance-management/attedance-rule/ip-binding")
);
export const LazyAttedanceGeoFencing = lazy(() =>
  import("../pages/attendance-management/attedance-rule/geo-fencing")
);
