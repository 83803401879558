/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  weekDaysMap: [],
  weeksOfMonth: [],
};

const shiftRuleSlice = createSlice({
  name: "shiftRule",
  initialState,
  reducers: {
    clearSetStates: (state) => {
      state.weekDaysMap = [];
      state.weeksOfMonth = [];
    },

    // set Days
    setWeekDays: (state, action) => {
      state.weekDaysMap = [...state.weekDaysMap, action.payload];
    },
   
    // remove week days
    removeWeekDays: (state, action) => {
      state.weekDaysMap = state.weekDaysMap?.filter((item) => {
        return item?.dayName !== action.payload;
      });
      state.weeksOfMonth = state.weeksOfMonth?.filter((item) => {
        return item?.dayName !== action.payload;
      });
    },

    // set weeks of month
    setWeeksOfMonth: (state, action) => {
      state.weeksOfMonth = [...state.weeksOfMonth, action.payload];
      state.weekDaysMap = state.weekDaysMap?.map((item) => {
        if (item?.dayName === action.payload?.dayName) {
          return {
            ...item,
            weekList: state.weeksOfMonth?.filter((item) => {
              return item?.dayName === action.payload?.dayName;
            }),
          };
        } else {
          return item;
        }
      });
    },

    // remove weeks of month
    removeWeeksOfMonth: (state, action) => {
      state.weeksOfMonth = state.weeksOfMonth?.filter((item) => {
        return (
          (item?.id !== action.payload?.id ||
            item?.dayName !== action.payload?.dayName) &&
          item?.id !== 1
        );
      });
      state.weekDaysMap = state.weekDaysMap?.map((item) => {
        if (item?.dayName == action.payload?.dayName) {
          return {
            ...item,
            weekList: state.weeksOfMonth?.filter((item) => {
              return item?.dayName == action.payload?.dayName && item?.id !== 1;
            }),
          };
        } else {
          return item;
        }
      });
    },

    setSelectAll: (state, action) => {
      state.weeksOfMonth = [
        ...state.weeksOfMonth,
        ...(action?.payload?.monthWeeks ?? []),
      ];
      state.weekDaysMap = action?.payload?.monthDays;
    },
    removeSelectAll: (state, action) => {
      state.weekDaysMap = state.weekDaysMap?.map((item) => {
        if (item?.dayName === action.payload?.dayName) {
          return {
            ...item,
            weekList: [],
          };
        } else {
          return item;
        }
      });
    },
    editWeekOffWeekList(state, action) {
      state.weeksOfMonth = action.payload.monthWeeks;
      state.weekDaysMap = action.payload.weekDay;
    },
  },
});

export const {
  clearSetStates,
  setWeekDays,
  removeWeekDays,
  setWeeksOfMonth,
  removeWeeksOfMonth,
  setSelectAll,
  removeSelectAll,
  addWeekOffConfiguration,
  editWeekOffWeekList
} = shiftRuleSlice.actions;

export default shiftRuleSlice.reducer;
