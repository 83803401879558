/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  editRecruiterData: {},
  recruiterAchieveList: [],
};

const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    setEditRecruiterDataList: (state, action) => {
      state.editRecruiterData = action.payload;
    },

    setRecruiterAchieveList: (state, action) => {
      state.recruiterAchieveList = action.payload;
    },
  },
});
export const { setEditRecruiterDataList, setRecruiterAchieveList } =
  activitySlice.actions;
export default activitySlice.reducer;
