/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  appraisalList: {},
  generateAppraisalUserList: {},
  addAppraisalDataById: {},
  getAppraisalRatingsDetails: {},
  appraisalListData: [],
};

const appraisalSlice = createSlice({
  name: "appraisal",
  initialState,
  reducers: {
    addAppraisalById: (state, action) => {
      state.addAppraisalDataById = action.payload;
    },

    setAppraisalList: (state, action) => {
      state.appraisalListData = action.payload;
    },
    setAppraisalRatingsDetailsById: (state, action) => {
      state.getAppraisalRatingsDetails = action.payload;
    },

    clearGenerateAppraisalUserData: (state) => {
      state.generateAppraisalUserList = {};
    },
  },
});
export const {
  getAppraisalList,
  addAppraisalById,
  editAppraisalById,
  setAppraisalList,
  clearGenerateAppraisalUserData,
  setAppraisalRatingsDetailsById,
} = appraisalSlice.actions;
export default appraisalSlice.reducer;
