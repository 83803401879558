/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  userDetails: {},
  notificationList: [],
  permissionList: [],
};

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      state.permissionList = action.payload?.roleAndPermissionList;
    },
    setNotificationData: (state, action) => {
      state.notificationList = action.payload;
    },
    clearUserDetails: (state) => {
      state.userDetails = [];
      state.permissionList = [];
    },
  },
});
export const { setUserDetails, setNotificationData,clearUserDetails } =
  registrationSlice.actions;
export default registrationSlice.reducer;
