/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  assignPermissionList: [],
  moduleIdList: [],
};

const rolesAndPermissionsSlice = createSlice({
  name: "rolesAndPermissions",
  initialState,
  reducers: {
    setPermissionList: (state, action) => {
      state.assignPermissionList = [
        ...state.assignPermissionList,
        ...action.payload.actionList,
      ];
      state.moduleIdList = action.payload.moduleList;
    },
    addInPermissionList: (state, action) => {
      state.assignPermissionList = [
        ...state.assignPermissionList,
        action.payload,
      ];
    },
    removeFromPermissionList: (state, action) => {
      state.assignPermissionList = state.assignPermissionList?.filter(
        (item) => {
          return item !== action?.payload;
        }
      );
    },

    addModulePermission: (state, action) => {
      state.moduleIdList = [...state.moduleIdList, action.payload];
    },

    removeModulePermission: (state, action) => {
      action.payload?.actionList.forEach((element) => {
        state.assignPermissionList = state.assignPermissionList.filter(
          (item) => {
            return item !== element?.id;
          }
        );
      });
      state.moduleIdList = state.moduleIdList.filter(
        (item) => item !== action.payload?.moduleId
      );
    },

    clearSetStates: (state) => {
      state.moduleIdList = [];
      state.assignPermissionList = [];
    },
  },
});
export const {
  setPermissionList,
  addInPermissionList,
  removeFromPermissionList,
  addModulePermission,
  removeModulePermission,
  clearSetStates,
} = rolesAndPermissionsSlice.actions;
export default rolesAndPermissionsSlice.reducer;
